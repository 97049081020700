import React, { useState, useRef, useEffect } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  ListItem,
  ListItemText,
  ListSubheader,
  Box,
  IconButton,
  Typography,
} from '@mui/material';
import { Close } from '@mui/icons-material';
import { FixedSizeList } from 'react-window';
import { useTranslation } from 'react-i18next';
import { formatNum } from '../utils';
import moment from 'moment';

import CustomDialog from './CustomDialog';
import { colors } from '../styles';
import noWinnings from '../styles/account/mywinning-img-pleadingface@3x.png'

export default function ListDialogComponent({
  setOpen,
  open,
  data,
  currentToken,
}) {
  const { t } = useTranslation();
  const handleClose = () => setOpen(false);

  const { winningRounds = [], winningDates, winningPrizes } = data || {};

  if (!winningRounds.length) {
    return (
      <CustomDialog
      open={open}
      handleClose={handleClose}
      title={null}
    >
      {/* add image here */}
      <DialogContent sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', p: 0, }}>
          <img style={{width: 240, height: 240, margin: 'auto'}} src={noWinnings} alt="no winnings" />
          <Typography variant="h2" color={colors.account.text1} fontSize={'1.3rem'} align="center">
            {t('No prizes... yet')}
          </Typography>
          <Typography variant="h6" color={colors.account.text4} align="center">
        {t('Better luck next time!')}
          </Typography>
      </DialogContent>
    </CustomDialog>
    )
  }

  return (
    <CustomDialog
      open={open}
      handleClose={handleClose}
      title={t('Total winnings')}
    >
      <DialogContent sx={{ borderTop: 'none' }}>
        <DialogContentText
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            pb: 0.5,
            px: 1.5,
          }}
        >
          <Box sx={{ flexGrow: 2 }}>
            <Typography variant="h6" color={colors.account.text4}>
              {t('Prize amount')}
            </Typography>
          </Box>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="h6" color={colors.account.text4}>
              {t('Draw')}
            </Typography>
          </Box>
          <Box sx={{ flexGrow: 1, textAlign: 'center' }}>
            <Typography variant="h6" color={colors.account.text4}>
              {t('Date')}
            </Typography>
          </Box>
        </DialogContentText>
        <FixedSizeList
          height={(56 + 8) * 6}
          itemSize={56}
          itemCount={winningRounds?.length}
          overscanCount={5}
        >
          {({ index, style }) => {
            return (
              <ListItem
                style={{ ...style }}
                key={index}
                component="div"
                disableGutters
                sx={{
                  backgroundColor: colors.account.backgroundColor1,
                  borderRadius: '24px',
                }}
                divider
              >
                <ListItemText
                  primary={
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        color: colors.account.text1,
                        px: 1.5,
                      }}
                    >
                      <Box sx={{ flexGrow: 2, display: 'flex', alignItems: 'center' }}>
                        <Typography color={colors.account.text2} variant="h2" fontSize={'1.25rem'}>
                          {formatNum(
                            winningPrizes[index],
                            currentToken,
                            true,
                          )}
                        </Typography>
                      </Box>
                      <Box sx={{ flexGrow: 1 }}>
                        <Typography color={colors.account.text2} variant="h6" fontSize="1.25rem">
                          {`#${formatNum(winningRounds[index])}`}
                        </Typography>
                      </Box>
                      <Box sx={{ flexGrow: 1, textAlign: 'end' }}>
                        <Typography color={colors.account.text2} variant="h6" fontSize="1.25rem">
                          {moment
                            .unix(winningDates[index])
                            .format('MMM D, YYYY')}
                        </Typography>
                      </Box>
                    </Box>
                  }
                />
              </ListItem>
            );
          }}
        </FixedSizeList>
      </DialogContent>
    </CustomDialog>
  );
}
