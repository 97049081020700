import React, { useState, useEffect } from 'react';
import {
  Container,
  Box,
  FormControl,
  OutlinedInput,
  Input,
  InputLabel,
  FormHelperText,
  InputAdornment,
  Select,
  MenuItem,
  TextField,
  Card,
  Button,
  Paper,
  Typography,
  Stack,
  Link,
  IconButton,
  Grid,
  Avatar,
  Tooltip,
  Fade,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Marquee from 'react-fast-marquee';
import { styled } from '@mui/material/styles';
import { useSnackbar } from 'notistack';

// utils
import {
  SUPPORTED_TOKENS_ARRAY,
  KLAY,
  ADDRESSES,
  CONTRACT,
  INDEX_KEYS,
} from '../constants';
import { BN, formatNum, BNFormat } from '../utils';
import { colors } from '../styles';
// hooks
import { useWeb3Context } from '../hooks/web3Context';
// components
import PageComponent from '../components/PageComponent';
import ConnectButton from '../components/ConnectButton';
import CountdownComponent from '../components/CountdownComponent';
import DepositButtonAction from '../components/DepositButtonAction';
import SelectToken, { iconObj } from '../components/SelectToken';
import { MobileRoot } from '../components/AppBar';
import HelpButton from '../components/HelpButton';
import BottomFooter from '../components/BottomFooter';
// styles
import Logo from '../styles/logo/klay-horizontal-logo.svg';
import Dot from '../styles/logo/oval-8-x-8-copy-3.svg';
import './Deposit.css';

export const BreakpointTypography = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    fontSize: '3.75rem',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '6.75rem',
  },
}));

const VariantTypography = ({
  children,
  align = 'left',
  variant = 'h1',
  color = 'primary',
}) => {
  return (
    <BreakpointTypography
      variant={variant}
      sx={{ zIndex: 10, lineHeight: 1.04 }}
      align={align}
      color={color}
    >
      {children}
    </BreakpointTypography>
  );
};

const GradientTypography = ({
  children,
  isNonMember,
  index,
  align = 'left',
}) => {
  return (
    <BreakpointTypography
      variant="h1"
      sx={{
        background:
          'linear-gradient(to right, #00b1ff 0%, #004cff 36%, #5252ff 70%, #ba52ff 100%)',
        ['-webkit-background-clip']: 'text',
        ['-webkit-text-fill-color']: 'transparent',
        // backgroundImage: 'linear-gradient(to right, #00b1ff 0%, #004cff 36%, #5252ff 70%, #ba52ff 100%)'
        width: 'fit-content',
        margin: align === 'left' ? 0 : 'auto',
        zIndex: 10,
      }}
      align={align}
      color="#4338fe"
    >
      {children}
    </BreakpointTypography>
  );
};

const Deposit = () => {
  const { connected } = useWeb3Context();
  const { t } = useTranslation();
  const { data, totalPrizeInDollars, prizeInTokens, isLoading } = useSelector(
    (state) => state.rounds,
  );
  const { address, data: userData } = useSelector((state) => state.user);
  const { enqueueSnackbar } = useSnackbar();

  // state
  const [params, setToken] = useSearchParams({ currentToken: KLAY });
  const token = params.get('currentToken') || KLAY;
  const [amount, setAmount] = useState('');
  const [fadeIn, setfadeIn] = useState(true);

  // button
  const { allowance } = userData?.length ? userData[INDEX_KEYS[token]] : {};
  const { data: balances } = useSelector((state) => state.walletBalance);
  const balance = balances[token];

  const { currentRound, totalDeposit, roundEndsAt } = data?.length
    ? data[INDEX_KEYS[token]]
    : {};

  const estimateItems = [
    [
      t(`Estimated prize on ${token} pool`),
      formatNum(prizeInTokens[token], token, true, false),
    ],
    [
      t(`Total deposit on ${token} pool`),
      formatNum(totalDeposit, token, true, false, 0), // no decimal deposit any more
    ],
  ];

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setfadeIn(false);

    setTimeout(() => {
      setToken({ currentToken: value }, { replace: true });
      setAmount('');
      setfadeIn(true);
    }, 500);

  };

  const handleAmountChange = (event) => {
    const {
      target: { value },
    } = event;

    if (/^[0123456789]+$/.test(value) || !value) {
      setAmount(value);
    }
  };

  const title = `Win $${formatNum(
    totalPrizeInDollars,
    false,
    false,
    false,
    3,
  )} this week`;

  if (!connected) {
    return (
      <>
        <PageComponent isLoading={isLoading} maxWidth="lg" showMarquee>
          <Box>
            <Grid container justifyContent="center" flexDirection="row">
              {/* left box */}
              <Grid item flexGrow={1} sx={{ zIndex: 10 }}>
                <Box justifyContent="start">
                  {title.split(' ').map((w, i) => {
                    if (i === 1) {
                      return <GradientTypography key={w}>{w}</GradientTypography>;
                    }
                    return <VariantTypography key={w}>{w}</VariantTypography>;
                  })}
                </Box>
                <Box sx={{ marginTop: 3 }}>
                  <ConnectButton size="large" />
                </Box>
                <Box textAlign="center" sx={{ width: '50%' }}>
                  <HelpButton />
                </Box>
              </Grid>
              {/* right box */}
              <Grid
                item
                sx={{
                  borderRadius: '48px',
                  pt: 2,
                  pl: 0,
                  backgroundColor: '#fff',
                  maxHeight: 562,
                  minHeight: 350,
                  zIndex: 10,
                }}
                flexGrow={1}
                className="drawBox"
              >
                <Box sx={{ mb: 1 }}>
                  <Typography color={colors.deposit.text1} align="center" variant="h5" fontSize="1.25rem">
                    {`Draw #${currentRound || '-'}`}
                  </Typography>
                </Box>
                <Box>
                  <CountdownComponent roundEndsAt={roundEndsAt} />
                </Box>
              </Grid>
            </Grid>
          </Box>
          <BottomFooter />
        </PageComponent>
      </>
    );
  }

  return (
    <PageComponent isLoading={isLoading}>
      <Box sx={{ zIndex: 10 }}>
        <Box>
          <Box>
            <Typography
              align="center"
              color={colors.deposit.text3}
              variant="h1"
              fontSize='2rem'
              sx={{ letterSpacing: '-0.5px' }}
            >
              {t(`Let's Klay Together!`)}
            </Typography>
            <GradientTypography align="center">{`$${formatNum(
              totalPrizeInDollars,
              false,
              false,
              false,
              3,
            )}`}</GradientTypography>
          </Box>
        </Box>
        <Box>
          <CountdownComponent roundEndsAt={roundEndsAt} isWhite />
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Box sx={{ zIndex: 10 }}>
          <Typography variant="h2" fontSize="1.25rem">{t('Deposit with')}</Typography>
        </Box>
        <SelectToken currentValue={token} handleChange={handleChange} />
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Box sx={{ zIndex: 10 }}>
          <Typography variant="h2" fontSize="1.25rem">{t('Amount')}</Typography>
        </Box>
        <FormControl sx={{}} variant="outlined">
          <OutlinedInput
            id="outlined-adornment-token"
            value={amount}
            type="number"
            fullWidth
            onChange={handleAmountChange}
            placeholder="0"
            startAdornment={
              <Fade
                in={fadeIn}
                ease="ease"
                timeout={{
                  appear: 500,
                  enter: 300,
                  exit: 500,
                }}
              >
                <InputAdornment position="start">
                  <Avatar
                    src={iconObj[token]}
                    sx={{ height: 32, width: 32, mr: 0.5 }}
                  />
                  <Typography
                    fontSize="2rem"
                    variant="h2"
                    color={colors.account.text1}
                  >
                    {token}
                  </Typography>
                </InputAdornment>
              </Fade>
            }
            aria-describedby="outlined-token-helper-text"
            inputProps={{
              'aria-label': 'token',
            }}
            sx={{
              px: 2,
              // py: 1,
            }}
          />
        </FormControl>
        <Box
          sx={{ display: 'flex', justifyContent: 'end', alignItems: 'start' }}
        >
          <Button
            size="medium"
            variant="text"
            fullWidth={false}
            sx={{ fontWeight: 600, fontSize: '1rem', textTransform: 'none', py: 0, height: 20, mt: 1 }}
            onClick={() => {
              setAmount(Math.floor(formatNum(balance, token, false, true)));
            }}
          >
            {`Balance: ${formatNum(balance, token, true)}`}
            <Button
              variant="outlined"
              size="small"
              fullWidth={false}
              sx={{
                ml: 1,
                height: 32,
                color: '#fff',
                borderColor: '#fff',
                '&:hover': {
                  borderColor: '#fff',
                  color: '#fff',
                }
              }}
            >
              MAX
            </Button>
          </Button>
        </Box>
      </Box>

      <Card
        sx={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          backgroundColor: '#4040ff80',
          px: 2,
          py: 1,
          zIndex: 10,
        }}
      >
        <Fade
          in={fadeIn}
          ease="ease"
          timeout={{
            appear: 500,
            enter: 300,
            exit: 500,
          }}
        >
          <Box>
            {estimateItems.map((row, i) => {
              if (!row) {
                return null;
              }
              return (
                <Box
                  key={`row-${i}`}
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  {row.map((item, index) => (
                    <Box
                      key={item}
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                      }}
                    >
                      <Typography variant={index === 0 ? 'h5' : 'h2'} sx={{ textTransform: 'none' }} fontSize="1rem" >{item}</Typography>
                      {/* {i === 0 && index === 0 && (
                      <Tooltip title="TODO" placement="bottom-start">
                        <IconButton sx={{'&hover': {backgroundColor: 'transparent', ml: 0.5}}}>
                          <HelpOutline color="primary" sx={{ height: 22 }} />
                        </IconButton>
                      </Tooltip>
                    )} */}
                    </Box>
                  ))}
                </Box>
              )
            })}
          </Box>
        </Fade>
      </Card>
      <Box textAlign="center">
        <DepositButtonAction
          amount={amount}
          token={token}
          cb={(message, { key, persist }) => {
            enqueueSnackbar(message, {
              key,
              persist,
            });
            setAmount('');
          }}
        />
        <Box textAlign="center" sx={{ zIndex: 10 }}>
          <HelpButton />
        </Box>
      </Box>
      <BottomFooter />
    </PageComponent>
  );
};

export default Deposit;
