// nouns
export const KLAY = 'KLAY';
export const KUSDT = 'KUSDT';
export const KSP = 'KSP';
export const PRIZE_POOL = 'PRIZE_POOL';
export const TOKEN = 'TOKEN';
export const KLAYTOGETHER_DATA = 'KLAYTOGETHER_DATA';
export const COMMON = 'COMMON';

export const SUPPORTED_TOKENS_ARRAY = [KLAY, KUSDT];
export const INDEX_KEYS = {
  0: KLAY,
  1: KUSDT,
  [KLAY]: 0,
  [KUSDT]: 1,
};

export const ADDRESSES = {
  [PRIZE_POOL]: {
    [KLAY]: '0x4c0ecF35621874ea178e640F15621279E5AF0Cf6',
    [KUSDT]: '0x9cc9EA2f20d41A1AdAc927eFFACe8E423b4BF697',
  },
  [TOKEN]: {
    [KUSDT]: '0xcee8faf64bb97a73bb51e115aa89c17ffa8dd167',
  },
  [KLAYTOGETHER_DATA]: {
    [COMMON]: '0xF2468eaC810891DEEF85eABa0BcF49A21D58e400',
  },
};

export const DECIMAL = {
  KLAY: 18,
  KUSDT: 6,
  KSP: 18,
};

export const DEFAULT_NETWORK_URL = 'https://internal.cypress.klaytn.net:8651';
export const DEFAULT_NETWORK_ID = 8217;

export const coinGeckoDict = {
  [KLAY]: 'klay-token',
  [KUSDT]: 'tether',
  [KSP]: 'klayswap-protocol',
};

export const GAS_BUFFER = 1.2;
export const DOCS_URL = 'https://klay-together.gitbook.io/product-docs/';
export const TELEGRAM_URL = 'https://t.me/+0aPIWW2DoT5hN2Y5'
export const GITHUB_URL = 'https://github.com/KlayTogether/klaytogether-contract'
