import React, { useState, useRef, useEffect } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  ListItem,
  ListItemText,
  ListSubheader,
  Box,
  IconButton,
  Typography,
  Link,
  Snackbar,
  Card,
  Stack,
  Chip,
  Divider,
  Grid,
} from '@mui/material';
import { Close, CallMade } from '@mui/icons-material';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import { truncAddress } from '../utils';
import CustomDialog from './CustomDialog';
import { colors } from '../styles';

export default function AccountDialogComponent({
  setOpen,
  open,
  account,
  disconnect,
}) {
  const { t } = useTranslation();
  const handleClose = () => setOpen(false);
  const { enqueueSnackbar } = useSnackbar();

  return (
    <CustomDialog
      title={null}
      open={open}
      handleClose={handleClose}
    >
      <Stack spacing={1} sx={{
        px: 1.5,
      }}>
        <DialogContent
          sx={{
            backgroundColor: colors.account.backgroundColor1,
            borderRadius: '24px',
          }}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box>
              <Typography variant="h2" fontSize={'1.25rem'} color={colors.account.text1}>
                {t('Account Address')}
              </Typography>
              <Typography variant="h2" fontSize={'1.5rem'} color={colors.account.text3} sx={{ textTransform: 'none', mt: 0.25 }}>
                {truncAddress(account, true)}
              </Typography>
            </Box>
            <Box>
              <CopyToClipboard
                text={account}
                onCopy={() => {
                  enqueueSnackbar('Copied!', {
                    key: 'copy-address',
                  });
                }}
              >
                <Button variant="dialog-outlined" size="small">COPY</Button>
              </CopyToClipboard>
            </Box>
          </Box>
        </DialogContent>
        <DialogContent
          sx={{
            backgroundColor: colors.account.backgroundColor1,
            borderRadius: '24px',
          }}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box>
              <Typography variant="h2" fontSize={'1.25rem'} color={colors.account.text1}>
                {t('Connected to')}
              </Typography>
              <Typography variant="h2" fontSize={'1.5rem'} color={colors.account.text3} sx={{ textTransform: 'none', mt: 0.25,}}>
                {'Kaikas'}
              </Typography>
            </Box>
            <Box>
              <Button
                variant="dialog-outlined"
                size="small"
                onClick={() => {
                  disconnect();
                  handleClose();
                }}
              >
                {t('Disconnect wallet')}
              </Button>
            </Box>
          </Box>
        </DialogContent>
      </Stack>
    </CustomDialog>
  );
}
