import React, { useState, useEffect } from 'react';
import {
  Paper,
  Box,
  Typography,
  Button,
  Card,
  Stack,
  CardActions,
  ListItemButton,
  ListItem,
  ListItemText,
  ListItemIcon,
  IconButton,
  Link,
  FormControl,
  Select,
  MenuItem,
  Divider,
  Fade,
  CircularProgress,
} from '@mui/material';
import { ChevronRight, KeyboardArrowDown } from '@mui/icons-material';
import { useTranslation, Trans } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { useSnackbar } from 'notistack';
import cx from 'classnames'

// utils
import { SUPPORTED_TOKENS_ARRAY, KLAY, INDEX_KEYS, DOCS_URL, KUSDT } from '../constants';
import { formatNum } from '../utils';
import { colors } from '../styles';
// components
import PageComponent from '../components/PageComponent';
import ListDialogComponent from '../components/ListDialogComponent';
import WithdrawButtonComponent from '../components/WithdrawButtonComponent';
import DepositButtonComponent from '../components/DepositButtonComponent';
import ConnectButton from '../components/ConnectButton';
import ClaimWinningsDialogComponent from '../components/ClaimWinningsDialogComponent';
import SelectToken from '../components/SelectToken';
import BottomFooter from '../components/BottomFooter';
// hooks
import { useWeb3Context } from '../hooks/web3Context';
// services
import { withdrawPrizeThunk } from '../services';
// style
import './Account.css';

const listItemTextBold = {
  color: '#fff',
  variant: 'h3',
  fontSize: '1.25rem'
};

const listItemText = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
};

const BreakpointListItem = styled(ListItemText)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: 12,
    paddingBottom: 12,
  },
  [theme.breakpoints.up('md')]: {
    paddingLeft: 32,
    paddingRight: 32,
    paddingTop: 24,
    paddingBottom: 24,
  },
}));

const Account = ({
  showClaim,
  setshowClaim,
}) => {
  const { t } = useTranslation();
  const { address } = useWeb3Context();
  const [showWinnings, setshowWinnings] = useState(false);
  const [params, setcurrentToken] = useSearchParams({ currentToken: KLAY });
  const currentToken = params.get('currentToken') || KLAY;
  const [fadeIn, setfadeIn] = useState(true);
  const { data, error, isLoading } = useSelector((state) => state.user);
  const { prizeInTokens, totalPrizeInDollars } = useSelector((state) => state.rounds);
  const { enqueueSnackbar } = useSnackbar();

  const dispatch = useDispatch();

  const handleChange = (e) => {
    const {
      target: { value },
    } = e;
    setfadeIn(false);

    setTimeout(() => {
      setcurrentToken({ currentToken: value }, { replace: true });
      setfadeIn(true);
    }, 500);
  };

  if (!address) {
    return (
      <PageComponent isAbsolute >
        <Stack spacing={2}>
          <Box>
            <Typography variant="h1" fontSize="2.5rem" align="center" sx={{ letterSpacing: '-0.5px' }}>
              {t(`Win $${formatNum(
                totalPrizeInDollars,
                false,
                false,
                false,
                3,
              )} this week!`
              )}
            </Typography>
          </Box>
          <Card sx={{ px: 2, py: 1 }}>
            <Typography variant="h6">
              {t('KlayTogether is a crypto-powered prize savings protocol.')}
            </Typography>
            <Typography variant="h6">
              {t(
                'To interact with the protocol, you will need to connect a wallet.',
              )}
            </Typography>
            <Typography variant="h6">
              {t('Please connect to your existing wallet.')}
            </Typography>
            <Typography variant="h6">
              <Link href={DOCS_URL} target="_blank">learn more</Link>
            </Typography>
          </Card>
          <Box justifyContent="center" display="flex">
            <ConnectButton />
          </Box>
        </Stack>
        <BottomFooter />
      </PageComponent>
    );
  }

  const {
    allowance,
    deposit,
    averageBalance,
    averageTotalSupply,
    totalWinnings,
    unclaimedWinnings,
    winningRounds,
    winningDates,
    winningPrizes,
  } = data?.length ? data[INDEX_KEYS[currentToken]] : {};

  return (
    <PageComponent>
      <ClaimWinningsDialogComponent
        open={showClaim}
        setOpen={setshowClaim}
        currentToken={currentToken}
        winningPrizes={winningPrizes}
      />
      <Box>
        <SelectToken currentValue={currentToken} handleChange={handleChange} />

        <Box
          sx={{
            backgroundImage: 'linear-gradient(to left, #a08eff, #5385ff)',
            borderRadius: '48px',
            position: 'relative',
            px: 1,
            py: 2,
            mt: 2,
          }}
          className={cx(
            'myDeposit', {
            tether: currentToken === KUSDT,
          }
          )}
        >
          <Fade
            in={fadeIn}
            ease="ease"
            timeout={{
              appear: 500,
              enter: 300,
              exit: 500,
            }}
          >
            <Box sx={{ p: 2 }}>
              <Stack spacing={1}>
                <Card
                  sx={{
                    background: 'none',
                    textAlign: 'center',
                    mb: 1,
                  }}
                >
                  <Typography variant="h3" fontSize="1.25rem" color="#fff">
                    {t('My deposit')}
                  </Typography>
                  <Typography variant="h1" fontSize="2.5rem" color="#fff">
                    {formatNum(deposit, currentToken, true, false, 0)}
                  </Typography>
                </Card>

                <Card
                  sx={{
                    backgroundColor: '#fff',
                    borderRadius: '16px',
                    p: 2,
                    pb: 4,
                    // zIndex: 10, // ?? 디자인 토큰 뒤로 빼기
                  }}
                >
                  <Typography
                    textAlign="center"
                    variant="h3"
                    fontSize="1.25rem"
                    color={colors.account.text1}
                  >
                    {t('Weekly odds')}
                  </Typography>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      mt: 2,
                    }}
                  >
                    <Box sx={{ textAlign: 'center', flex: 1 }}>
                      <Box>
                        <Typography variant="h2" fontSize="1.5rem" color={colors.account.text3}>
                          {formatNum(
                            prizeInTokens[currentToken],
                            currentToken,
                            true,
                            false,
                            3,
                          )}
                        </Typography>
                      </Box>
                      <Box>
                        <Typography variant="h6" fontSize="1rem" color={colors.account.text2}>
                          {t('Estimated prize')}
                        </Typography>
                      </Box>
                    </Box>
                    <Divider
                      sx={{ borderColor: colors.account.divider, borderWidth: 1 }}
                    />
                    <Box sx={{ textAlign: 'center', flex: 1 }}>
                      <Box>
                        <Typography variant="h2" fontSize="1.5rem" color={colors.account.text3}>
                          {`${formatNum(
                            (averageBalance / averageTotalSupply) * 100,
                            false,
                            false,
                            false,
                            1,
                          )}%`}
                        </Typography>
                      </Box>
                      <Box>
                        <Typography variant="h6" fontSize="1rem" color={colors.account.text2}>
                          {'My odds'}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Card>

                <Card
                  sx={{
                    backgroundColor: 'rgba(80,25,255,0.3)',
                    borderRadius: '16px',
                    px: 1,
                  }}
                >
                  <ListItemButton onClick={() => setshowWinnings(true)}>
                    <ListItemText
                      sx={listItemText}
                      primaryTypographyProps={listItemTextBold}
                      secondaryTypographyProps={listItemTextBold}
                      primary="Total winnings"
                      secondary={
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <Typography variant="h2" fontSize="1.25rem">
                            {formatNum(totalWinnings, currentToken, true)}
                          </Typography>
                          <IconButton edge="end" aria-label="see more">
                            <ChevronRight width={24} height={24} />
                          </IconButton>
                        </Box>
                      }
                    />
                  </ListItemButton>
                </Card>

                {Number(unclaimedWinnings) ? (
                  <Card
                    className="gradient-border"
                    sx={{
                      borderRadius: '16px',
                      backgroundColor: '#5019ff',
                      mx: 0.1,
                      my: 0.1,
                      px: 1,
                    }}
                  >
                    <ListItem sx={{}}>
                      <ListItemText
                        sx={listItemText}
                        primaryTypographyProps={listItemTextBold}
                        secondaryTypographyProps={listItemTextBold}
                        primary={t('Unclaimed winnings')}
                        secondary={
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Typography variant="h2" fontSize="1.25rem">
                              {formatNum(unclaimedWinnings, currentToken, true)}
                            </Typography>
                            <Button
                              size="small"
                              variant="contained"
                              sx={{
                                backgroundColor: '#fff',
                                color: '#4338fe',
                                ml: 1,
                                fontSize: '0.75rem',
                                fontWeight: 700,
                                height: 33,
                                width: 70,
                                '&:hover': {
                                  backgroundColor: 'rgba(255,255,255, 0.8)',
                                },
                              }}
                              onClick={() => {
                                dispatch(
                                  withdrawPrizeThunk({
                                    address,
                                    cb: (message, { key }) => {
                                      if (key && key.includes('error')) {
                                        enqueueSnackbar(message, {
                                          key,
                                        });
                                      } else {
                                        setshowClaim(message);
                                      }
                                    },
                                    token: currentToken,
                                  }),
                                );
                              }}
                            >
                              {isLoading ? <CircularProgress size="1.5rem" /> : t('Claim')}
                            </Button>
                          </Box>
                        }
                      />
                    </ListItem>
                  </Card>
                ) : null}

                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: 1,
                    justifyContent: 'center',
                  }}
                >
                  <DepositButtonComponent token={currentToken} fullWidth />
                  <WithdrawButtonComponent token={currentToken} fullWidth />
                </Box>
              </Stack>
            </Box>
          </Fade>
        </Box>
      </Box>

      <ListDialogComponent
        open={showWinnings}
        setOpen={setshowWinnings}
        data={{
          winningRounds,
          winningDates,
          winningPrizes,
        }}
        currentToken={currentToken}
      />
      <BottomFooter />
    </PageComponent>
  );
};

export default Account;
