import React, { useState } from 'react';
import {
  Typography,
  Box,
  FormControl,
  Select,
  MenuItem,
  ListItem,
  ListItemText,
  IconButton,
  Avatar,
  Fade,
} from '@mui/material';
import { FixedSizeList } from 'react-window';
import { useTranslation, Trans } from 'react-i18next';
import Countdown from 'react-countdown';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { ChevronRight, ChevronLeft } from '@mui/icons-material';
import cx from 'classnames';
import { useSearchParams } from 'react-router-dom';
import { styled } from '@mui/material/styles';

import { SUPPORTED_TOKENS_ARRAY, KLAY, INDEX_KEYS, KUSDT } from '../constants';
import PageComponent from '../components/PageComponent';
import BottomFooter from '../components/BottomFooter';
import { formatNum, truncAddress } from '../utils';
import { colors } from '../styles';
import { BreakpointTypography } from './Deposit'

import './Prize.css';

export const callSize = 50;

const colorByDiv = (index) => {
  if (index % 4 === 3) {
    return '#60f0aa';
  }
  if (index % 4 === 2) {
    return 'rgba(117, 91, 255, 0.69)';
  }
  if (index % 4 === 1) {
    return '#4033ff';
  }
  if (index % 4 === 0) {
    return '#40bfff';
  }
};

export const BreakpointListItem =  styled(ListItem)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: 12,
    paddingBottom: 12,
  },
  [theme.breakpoints.up('md')]: {
    paddingLeft: 32,
    paddingRight: 32,
    paddingTop: 24,
    paddingBottom: 24,
  },
}));

export const BreakpointListItemTypographyH2 =  styled(Typography)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    fontWeight: 600,
    fontSize: '1rem',
  },
  [theme.breakpoints.up('md')]: {
    fontWeight: 700,
  },
}));

export const BreakpointListItemTypographyH3 =  styled(Typography)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    fontWeight: 500,
    fontSize: '0.75rem',
  },
  [theme.breakpoints.up('md')]: {
    fontWeight: 500,
  },
}));

const Prize = () => {
  const { t } = useTranslation();
  const [params, setcurrentToken] = useSearchParams({ currentToken: KLAY });
  const currentToken = params.get('currentToken') || KLAY;
  const [currentCallSize, setcurrentCallSize] = useState(callSize);
  const [fadeIn, setfadeIn] = useState(true);
  const { data, isLoading, error } = useSelector((state) => state.prizes);
  const { data: roundData, prizeInTokens, isLoading: roundsIsLoading } = useSelector(
    (state) => state.rounds,
  );
  const { rounds, endTimes, prizes, winners } = data?.length
    ? data[INDEX_KEYS[currentToken]]
    : {};

  const handleChange = (value) => {
    setfadeIn(false);

    setTimeout(() => {
      setcurrentToken({ currentToken: value }, { replace: true });
      setfadeIn(true);
    }, 500);
  };

  const renderArr = ['days', 'hours', 'minutes'];
  const renderer = (props) => {
    // { days, hours, minutes, completed } = props
    if (props.completed) {
      return <span>(Draw pending)</span>;
    }
    const timeStrArr = renderArr.map((key) => {
      if (props[key]) {
        return `${props[key]} ${key}`;
      }
      return '';
    });

    return <span>{`Draws in ${timeStrArr.join(' ')}`}</span>;
  };

  const {
    currentRound,
    accumulatedPrize,
    accumulatedKsp,
    totalDeposit,
    roundEndsAt,
  } = roundData?.length ? roundData[INDEX_KEYS[currentToken]] : {};

  const hasNoNextDraw = moment.unix(Number(roundEndsAt)).isBefore(moment(Date.now()))

  return (
    <PageComponent isLoading={isLoading || roundsIsLoading}>
      {/* top */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <IconButton
          onClick={() => {
            handleChange(KLAY)
          }}
          disabled={currentToken === KLAY}
          sx={{
            height: 32,
            p: 0,
          }}
          size="large"
        >
          <ChevronLeft />
        </IconButton>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexGrow: 1,
            height: 320,
            borderRadius: '48px',
          }}
          className={cx({
            [currentToken]: true,
          })}
        >
          <Fade
            in={fadeIn}
            ease="ease"
            timeout={{
              appear: 500,
              enter: 300,
              exit: 500,
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Typography variant="h2" fontSize="1.25rem">{t('Prizes on')}</Typography>
              <BreakpointTypography variant="h1">{currentToken}</BreakpointTypography>
              <Typography variant="h2" fontSize="1.25rem">{'pool'}</Typography>
            </Box>
          </Fade>
        </Box>
        <IconButton
          onClick={() => {
            handleChange(KUSDT)
          }}
          disabled={currentToken === KUSDT}
          sx={{
            height: 32,
            p: 0,
          }}
          size="large"
        >
          <ChevronRight />
        </IconButton>
      </Box>

      {/* list */}
      <Fade
        in={fadeIn}
        ease="ease"
        timeout={{
          appear: 500,
          enter: 300,
          exit: 500,
        }}
      >
        <Box>
          <Typography align="left" variant="h2" fontSize="1.25rem" gutterBottom>
            {t('Past Draws')}
          </Typography>
          <Box
            sx={{
            }}
          >
            <FixedSizeList
              height={(128 + 14) * 4}
              itemSize={128 + 14} // index height
              overscanCount={5}
              itemCount={rounds?.length ? rounds?.length + 1 : 1}
              onItemsRendered={({ overscanStartIndex }) => {
                if (overscanStartIndex === 1) {
                  // start calling next batch
                  setcurrentCallSize(currentCallSize + callSize);
                }
              }}
            >
              {({ index: tempIndex, style }) => {
                const index = tempIndex - 1;
                  return (
                    <BreakpointListItem
                      style={{
                        ...style,
                        backgroundColor: '#fff',
                        borderRadius: '16px',
                        height: 128,
                        borderBottom: 'none',
                      }}
                      // sx={{
                      //   px: 2,
                      //   py: 1,
                      // }}
                      key={currentRound}
                      component="div"
                      divider
                    >
                      <ListItemText
                        primary={
                          <Box
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                            }}
                          >
                            <Avatar
                              sx={{
                                p: 0.5,
                                backgroundColor: colorByDiv(tempIndex),
                              }}
                            >
                              <BreakpointListItemTypographyH2
                                // variant="h2"
                                fontSize="1.25rem"
                                color={'#fff'}
                              >{`#${tempIndex === 0 ? (currentRound || '-') : rounds[index]}`}</BreakpointListItemTypographyH2>
                            </Avatar>
                            <Box
                              sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                flexGrow: 1,
                                ml: 1,
                              }}
                            >
                              <Box>
                                <BreakpointListItemTypographyH2
                                  color={colors.account.text2}
                                  fontSize='1.25rem'
                                >
                                  {`${moment
                                    .unix(Number(tempIndex === 0 ? roundEndsAt : endTimes[index]))
                                    .format('ddd, MMM D, h:mm a')}`}
                                </BreakpointListItemTypographyH2>
                              </Box>
                              <Box>
                                <BreakpointListItemTypographyH3
                                  fontSize={'1rem'}
                                  color={colors.account.text2}
                                >
                                  {tempIndex === 0 ? (
                                     <Countdown
                                     intervalDelay={0}
                                     precision={3}
                                     date={moment
                                       .unix(Number(roundEndsAt))
                                       .toDate()}
                                     renderer={renderer}
                                   />
                                  ) : `Winner: ${truncAddress(winners[index], true)}`}

                                </BreakpointListItemTypographyH3>
                              </Box>
                            </Box>

                            {tempIndex === 0 ? (hasNoNextDraw ? (
                              <Box>
                              <Box>
                                <BreakpointListItemTypographyH2
                                  color={colors.account.text1}
                                  fontSize="1.5rem"
                                >
                                  (Pending)
                                </BreakpointListItemTypographyH2>{' '}
                              </Box>
                            </Box>
                            ) : (
                              <Box>
                                <Box>
                                  <BreakpointListItemTypographyH2
                                    color={colors.account.text1}
                                    fontSize="1.5rem"
                                  >
                                    {formatNum(
                                      prizeInTokens[currentToken],
                                      currentToken,
                                      true,
                                      false,
                                    )}
                                  </BreakpointListItemTypographyH2>{' '}
                                </Box>
                                <Box
                                  sx={{
                                    display: 'flex',
                                    justifyContent: 'end',
                                  }}
                                >
                                  <Typography
                                    variant="h6"
                                    align="right"
                                    fontSize='0.75rem'
                                    color={colors.account.text2}
                                  >
                                    {t('estimated')}
                                  </Typography>
                                </Box>
                              </Box>

                            )) : (
                              <Box>
                              <Box>
                                <BreakpointListItemTypographyH2
                                  color={colors.account.text1}
                                  fontSize="1.5rem"
                                >
                                  {formatNum(prizes[index], currentToken, true)}
                                </BreakpointListItemTypographyH2>{' '}
                              </Box>
                            </Box>
                            )}
                          </Box>
                        }
                      />
                    </BreakpointListItem>
                  );
              }}
            </FixedSizeList>
          </Box>
        </Box>
      </Fade>
      <BottomFooter />
    </PageComponent>
  );
};

export default Prize;
