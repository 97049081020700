import React, { useState, useRef, useEffect } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  ListItem,
  ListItemText,
  ListSubheader,
  Box,
  IconButton,
  Typography,
  Link,
  Snackbar,
  Card,
  Stack,
  Chip,
  Divider,
  Grid,
} from '@mui/material';
import { Close, CallMade } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

export default function CustomDialog({
  title,
  handleClose,
  children,
  open,
  styles = {},
}) {
  return (
    <Dialog
      open={open}
      fullWidth
      onClose={handleClose}
      PaperProps={{
        sx: {
          borderRadius: '40px', // consle warning?
          padding: 1,
          pb: 5,
          ...styles,
        },
      }}
    >
      <DialogTitle id="scroll-dialog-title" align="center" variant="h2" sx={{fontSize: '1.25rem', pt: 4, pb: 0}}>
        {title}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: 20,
          top: 20,
          width: 30,
          height: 30,
          color: (theme) => theme.palette.secondary.main,
        }}
      >
        <Close />
      </IconButton>
      {children}
    </Dialog>
  );
}
