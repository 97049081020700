import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Box,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  ButtonGroup,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  BottomNavigation,
  BottomNavigationAction,
  Paper,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { red, green, blue } from '@mui/material/colors';
import { PATH_ACCOUNT, PATH_LANDING, PATH_PRIZE } from '../routes';
import { COLOR_OBJ } from '../styles';

export const PcRoot = styled('div')(({ theme }) => ({
  padding: theme.spacing(1),
  [theme.breakpoints.up('md')]: {
    display: 'none',
  },
}));

const labelObj = {
  0: PATH_LANDING,
  1: PATH_PRIZE,
  2: PATH_ACCOUNT,
  Deposit: PATH_LANDING,
  Prize: PATH_PRIZE,
  Account: PATH_ACCOUNT,
};

export default function BottomNavigationComponent(props) {
  const [currentValue, setValue] = useState(0);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { pathname } = useLocation();
  let bgColor = '#56b7ff';
  if (pathname === PATH_PRIZE) {
    bgColor = '#30a9d4';
  } else if (pathname === PATH_ACCOUNT) {
    bgColor = '#7228e6';
  }
  return (
    <PcRoot>
      <Box sx={{ zIndex: 10, position: 'fixed', bottom: 0, left: 0, right: 0 }}>
        <BottomNavigation
          showLabels
          value={currentValue}
          onChange={(event, newValue) => {
            setValue(newValue);
            navigate(labelObj[newValue]);
          }}
          sx={{
            backgroundColor: bgColor,
          }}
        >
          {[t('Deposit'), t('Prize'), t('Account')].map((label) => (
            <BottomNavigationAction
              key={label}
              label={t(label)}
              sx={{
                color: '#fff',
                fontWeight: 600,
                fontSize: '1rem',
                opacity: pathname === labelObj[label] ? 1 : 0.5,
              }}
            />
          ))}
        </BottomNavigation>
      </Box>
    </PcRoot>
  );
}
