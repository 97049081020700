import React from 'react';
import {
  Box,
  Typography,
  FormControl,
  Select,
  MenuItem,
  Avatar,
} from '@mui/material';
import { KeyboardArrowDown } from '@mui/icons-material';

import { SUPPORTED_TOKENS_ARRAY, KLAY, KUSDT } from '../constants';

import klaypng from '../styles/deposit/icon-klayton-32@3x.png'
import kusdtsvg from '../styles/deposit/icon-usdt-32.svg';

export const iconObj = {
  [KLAY]: klaypng,
  [KUSDT]: kusdtsvg,
};

const SelectToken = ({ currentValue, handleChange }) => {
  return (
    <FormControl fullWidth>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={currentValue}
        onChange={handleChange}
        sx={{
          borderRadius: '16px',
          backgroundColor: '#fff',
        }}
        renderValue={(v) => (
          <Box
            sx={{
              px: 1.5,
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              gap: 0.5,
            }}
          >
            <Avatar src={iconObj[v]} sx={{ height: 32, width: 32 }} />
            <Typography textAlign="start" variant="h2" fontSize="2rem" sx={{ letterSpacing: '-0.4px' }} color="#000">
              {v}
            </Typography>
          </Box>
        )}
        IconComponent={() => (
          <KeyboardArrowDown color="secondary" sx={{ pr: 1, height: 24 }} />
        )}
      >
        {SUPPORTED_TOKENS_ARRAY.map((value) => (
          <MenuItem
            sx={{
              width: '100%',
              borderRadius: '16px',
            }}
            value={value}
            key={value}
            disabled={value === currentValue}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <Avatar
                src={iconObj[value]}
                sx={{ height: 32, width: 32, marginRight: 0.5 }}
              />
              <Typography variant="h3" fontSize="1.5rem" sx={{ letterSpacing: '-0.4px' }} color={'#000'}>
                {value}
              </Typography>
            </Box>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SelectToken;
