import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Box,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  ButtonGroup,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  BottomNavigation,
  BottomNavigationAction,
  Paper,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Container,
} from '@mui/material';
import { ButtonUnstyled } from '@mui/base';
import { styled } from '@mui/material/styles';
import { red, green, blue } from '@mui/material/colors';
import useMediaQuery from '@mui/material/useMediaQuery';

import { PcRoot } from './BottomNavigation';
import AppBarConnectButton from './AppBarConnectButton';
import Logo from '../styles/logo/klaytogether-logo-horizontal@3x.png';
import LogoWhite from '../styles/logo/klaytogether-logo-white-horizontal@3x.png';
import { PATH_ACCOUNT, PATH_LANDING, PATH_PRIZE } from '../routes';
import { MoreVert } from '@mui/icons-material';

import { COLOR_OBJ } from '../styles';
import { ROUTE_NAME_OBJ } from '../routes';

export const MobileRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}));

export const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    minHeight: '5vh',
  },
  [theme.breakpoints.down('md')]: {
    minHeight: '10vh',
  },
}));

const StyledContainer = styled(Container)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    minHeight: '5vh',
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: 16,
    paddingBottom: 16,
  },
  [theme.breakpoints.up('md')]: {
    minHeight: '10vh',
    paddingLeft: 48,
    paddingRight: 48,
    paddingTop: 32,
    paddingBottom: 32,
  },
}));

const AppBarComponent = ({ setLang, currentLang }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const handleChange = (e) => {
    const {
      target: { value },
    } = e;
    setLang(value);
  };
  const [open, setOpen] = useState(false);

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));

  return (
    <>
      <AppBar position="static" color="transparent">
        <StyledToolbar
          sx={{ maxHeight: '10vh' }}
        >
          <StyledContainer maxWidth={isMobile ? null : 'lg'} sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            py: 2,
          }}>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
              onClick={() => navigate(PATH_LANDING)}
            >
              <img
                style={{ height: 32 }}
                src={pathname === PATH_ACCOUNT ? LogoWhite : Logo}
                alt="logo"
              />
            </IconButton>
            <MobileRoot>
              <ButtonGroup
                variant="text"
                aria-label="outlined primary button group"
                color="primary"
                size="large"
              >
                {[PATH_LANDING, PATH_PRIZE, PATH_ACCOUNT].map((p) => {
                  return (
                    <Button
                      key={p}
                      sx={{
                        color:
                          pathname === PATH_ACCOUNT
                            ? '#fff'
                            : COLOR_OBJ.appBar.primary,
                        fontSize: '1rem',
                        fontWeight: 800,
                        '&:hover': {
                          opacity: 1,
                          textDecorationColor: `${COLOR_OBJ.appBar.primary}`,
                          textUnderlineOffset: '3px',
                          textDecoration: p === pathname ? 'underline' : 'none',
                          textDecorationThickness: '3px',
                        },
                        textDecoration: p === pathname ? 'underline' : 'none',
                        textDecorationColor:
                          pathname === PATH_ACCOUNT
                            ? '#fff'
                            : COLOR_OBJ.appBar.primary,
                        textUnderlineOffset: '3px',
                        textDecorationThickness: '3px',
                        opacity: p === pathname ? 1 : 0.3,
                      }}
                      onClick={() => navigate(p)}
                    >
                      {t(ROUTE_NAME_OBJ[p])}
                    </Button>
                  );
                })}
              </ButtonGroup>
            </MobileRoot>
            <Box
              sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
            >
              <Box>
                <AppBarConnectButton />
              </Box>
              {/* <Box>
              <MobileRoot>
                <FormControl sx={{ ml: 2, mt: 0, mb: 0 }}>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={currentLang} // todo
                    onChange={handleChange}
                  >
                    {['EN', 'KO'].map(value => (
                      <MenuItem value={value} key={value}>
                        {value}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </MobileRoot>
              <PcRoot>
                <IconButton
                  aria-label="more"
                  onClick={() => {
                    setOpen(!open);
                  }}
                >
                  <MoreVert />
                </IconButton>
              </PcRoot>
            </Box> */}
            </Box>
          </StyledContainer>
        </StyledToolbar>
        {/* <Dialog open={open} onClose={() => setOpen(false)}>
          <DialogTitle>
            <Typography>{t('Select language')}</Typography>
          </DialogTitle>
          <DialogContent>
            <Box
              component="form"
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'center',
              }}
            >
              <FormControl>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={currentLang} // todo
                  onChange={handleChange}
                >
                  {['EN', 'KO'].map(value => (
                    <MenuItem value={value} key={value}>
                      {value}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </DialogContent>
        </Dialog> */}
      </AppBar>
    </>
  );
};

export default AppBarComponent;
