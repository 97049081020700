import React, { useRef, useEffect, useState } from 'react';
import {
  Box,
  Stack,
  Container,
  CircularProgress,
  Backdrop,
} from '@mui/material';
import Lottie from 'react-lottie';
import { useLocation } from 'react-router-dom';
import * as animationData1 from '../styles/lottie/Main_Wave_Lottie.json';
import * as animationData2 from '../styles/lottie/Main_Wave_Lottie_02.json';
import * as animationData3 from '../styles/lottie/Main_Wave_Lottie_03.json';
import Logo from '../styles/logo/klay-horizontal-logo.svg';
import Dot from '../styles/logo/oval-8-x-8-copy-3.svg';
import { styled } from '@mui/material/styles';
import Marquee from 'react-fast-marquee';

import { MobileRoot } from './AppBar';
import { PATH_ACCOUNT, PATH_LANDING, PATH_PRIZE } from '../routes';
import { colors } from '../styles';

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData1,
};

const BreakpointContainer = styled(Container)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
  },
  [theme.breakpoints.up('md')]: {
    paddingTop: 50,
    paddingBottom: 80,
  },
}));

export default function PageComponent({ children, isLoading, maxWidth, showMarquee }) {
  const { pathname } = useLocation();
  const animData = {
    [PATH_ACCOUNT]: animationData3,
    [PATH_LANDING]: animationData1,
    [PATH_PRIZE]: animationData2,
  }
  const boxRef = useRef()
  const [rectHeight, setrectHeight] = useState(0);
  const handleResize = () => {
    if (boxRef?.current) {
      const { y, height } = boxRef.current.getBoundingClientRect()
      setrectHeight(height + 56)
    }

  }
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, [])

  return (
    <>
      <BreakpointContainer ref={boxRef} sx={{ minHeight: '90vh', display: 'flex', flexDirection: 'column' }} maxWidth={maxWidth || 'md'}>
        <MobileRoot>
          <Box
            sx={{
              position: 'fixed',
              left: 0,
              bottom: '-10px',
              width: '100%',
              zIndex: 0,
            }}
          >
            <Lottie
              options={{ ...defaultOptions, animationData: animData?.[pathname] }}
              isClickToPauseDisabled={true}
            />
          </Box>
        </MobileRoot>

        <Stack sx={{ flexGrow: 1, justifyContent: 'space-between', zIndex: 10 }} spacing={2}>{children}</Stack>
      </BreakpointContainer>
      {showMarquee && (
        <MobileRoot>
          <Marquee
            style={{
              backgroundColor: colors.deposit.backgroundColor2,
              paddingTop: '18px',
              paddingBottom: '18px',
              position: 'absolute',
              left: 0,
              top: rectHeight,
            }}
            gradient={false}
          >
            <div
              style={{
                display: ' flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              {Array(20).fill((
                <>
                  <img style={{ height: 20 }} src={Logo} />
                  <img
                    style={{ height: 8, marginLeft: 20, marginRight: 20 }}
                    src={Dot}
                  />
                </>
              ))}
            </div>
          </Marquee>
        </MobileRoot>
      )}
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading || false} // TODO: change to universal store
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}
