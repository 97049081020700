import React from 'react'
import {
  Typography,
  IconButton,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { HelpOutline } from '@mui/icons-material';

import { DOCS_URL } from '../constants'

export default () => {
  const { t } = useTranslation()

  return (
    <IconButton
      size="large"
      aria-label="help"
      onClick={() => {
        window.open(DOCS_URL, '_blank')
      }}
    >
      <Typography variant="h5" fontSize="1.25rem">{t('Help')}</Typography>
      <HelpOutline color="primary" sx={{ ml: 0.5, height: 22 }} />
    </IconButton>
  )
}
