import React from 'react';
import { Box, Typography, Link, Card } from '@mui/material';
import Countdown from 'react-countdown';
import { useTranslation, Trans } from 'react-i18next';
import moment from 'moment';

import { colors } from '../styles';

const VariantTypography = ({ children, color }) => {
  return (
    <Typography
      variant="h4"
      sx={{ fontWeight: 800 }}
      gutterBottom
      align="center"
      color={color}
    >
      {children}
    </Typography>
  );
};

const renderArr = ['DAY', 'HR', 'MIN', 'SEC'];

export default function CountdownComponent({ roundEndsAt, isWhite }) {
  const countdownDate = moment.unix(Number(roundEndsAt)).toDate();
  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return (
        <VariantTypography color={colors.deposit.text1}>
          <Trans i18nKey={'(Pending)'} />
        </VariantTypography>
      );
    } else {
      // Render a countdown
      const textColor = isWhite ? '#fff' : colors.deposit.text2;
      const squareColor = isWhite
        ? 'rgb(255, 255, 255, 0.3)'
        : colors.deposit.backgroundColor1;

      return (
        <Box display="flex" flexDirection="row" justifyContent="center">
          {[days, hours, minutes, seconds].map((v, i) => (
            <Box
              // alignItems="center"
              display="flex"
              flexDirection="row"
              alignItems="baseline"
              key={renderArr[i]}
            >
              <Box>
                <Box
                  sx={{
                    backgroundColor: squareColor,
                    width: '56px',
                    height: '56px',
                    borderRadius: '8px',
                  }}
                  justifyContent="center"
                  alignItems="center"
                  display="flex"
                >
                  <Typography variant="h4" align="center" color={textColor}>
                    {v || 0}
                  </Typography>
                </Box>
                <Box>
                  <Typography color={textColor} align="center">
                    {renderArr[i]}
                  </Typography>
                </Box>
              </Box>
              {i === 3 ? null : (
                <Box sx={{ width: '16px', height: '56px' }}>
                  <Typography variant="h4" align="center" color={textColor}>
                    :
                  </Typography>
                </Box>
              )}
            </Box>
          ))}
        </Box>
      );
    }
  };
  return (
    <Countdown
      intervalDelay={0}
      precision={3}
      date={countdownDate}
      renderer={(props) => renderer({ isWhite, ...props })}
    />
  );
}
