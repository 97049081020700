import React, { useState, useEffect } from 'react';
import {
  Typography,
  Box,
  FormControl,
  Select,
  MenuItem,
  ListItem,
  ListItemButton,
  ListItemText,
  ListSubheader,
  Stack,
  Button,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  Divider,
} from '@mui/material';
import { Close } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';

import { formatNum } from '../utils';
import { INDEX_KEYS } from '../constants';
import DepositButtonAction from './DepositButtonAction';
import CustomDialog from './CustomDialog';
import { colors } from '../styles';

const DepositDialogComponent = ({ token, onClose, open }) => {
  const { t } = useTranslation();

  const { data: userData } = useSelector((state) => state.user);
  const { allowance } = userData?.length ? userData[INDEX_KEYS[token]] : {};
  const { data: balances } = useSelector((state) => state.walletBalance);
  const balance = balances[token];

  const [amount, setAmount] = useState('');
  const { enqueueSnackbar } = useSnackbar();

  const handleClose = () => onClose(false);

  const handleAmountChange = (event) => {
    const {
      target: { value },
    } = event;

    if (/^[0123456789]+$/.test(value) || !value) {
      setAmount(value)
    }
  };

  return (
    <CustomDialog
      open={open}
      handleClose={handleClose}
      title={t(`Deposit ${token}`)}
    >
      <DialogContent>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
        >
          <Box
            sx={{
              backgroundColor: colors.account.backgroundColor1,
              borderRadius: '24px',
              px: 3,
              py: 2,
              mb: 2,
              width: '-webkit-fill-available',
              justifyContent: 'center',
            }}
          >
            <Box sx={{ display: 'flex', justifyContent: 'center', }}>
              <Button size="medium" variant="text" fullWidth={false} sx={{ fontWeight: 500, textTransform: 'none', py: 0, height: 20, color: colors.account.text1 }} onClick={() => {
                setAmount(Math.floor(formatNum(balance, token, false, true)))
              }}>
                <Typography
                  color={colors.account.text1}
                  variant="h6"
                  textAlign="center"
                >
                  {`${t('My balance')}: ${formatNum(balance, token)} ${token}`}
                </Typography>
                <Button
                  variant="outlined"
                  size="small"
                  fullWidth={false}
                  sx={{
                    ml: 1,
                    height: 32,
                    color: colors.account.text1,
                    borderColor: colors.account.text1,
                    '&:hover': {
                      borderColor: colors.account.text1,
                      color: colors.account.text1,
                    }
                  }}
                >
                  MAX
                </Button>
              </Button>
            </Box>

            <Box display="flex" justifyContent="center" flexDirection="column">
              <FormControl
                sx={{ m: 1, width: '25ch', alignSelf: 'center' }}
                variant="outlined"
              >
                <OutlinedInput
                  id="outlined-adornment-token"
                  value={amount}
                  type="number"
                  placeholder="0"
                  onChange={handleAmountChange}
                  startAdornment={
                    <InputAdornment position="start">{token}</InputAdornment>
                  }
                  aria-describedby="outlined-token-helper-text"
                  inputProps={{
                    'aria-label': 'token',
                  }}
                />
              </FormControl>
            </Box>
          </Box>
          <DepositButtonAction
            amount={amount}
            token={token}
            cb={(message, { key }) => {
              enqueueSnackbar(message, {
                key,
              });
              handleClose();
            }}
          />
        </Box>
      </DialogContent>
    </CustomDialog>
  );
};

export default DepositDialogComponent;
