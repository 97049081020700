import React, { useState, useRef, useEffect, useCallback } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  ListItem,
  ListItemText,
  ListSubheader,
  Box,
  IconButton,
  Typography,
  Link,
  Snackbar,
  Card,
  Stack,
  Chip,
  Divider,
  Grid,
  Backdrop,
} from '@mui/material';
import { Close, CallMade } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import ReactCanvasConfetti from "react-canvas-confetti";

import { withdrawPrizeThunk } from '../services';
import { useWeb3Context } from '../hooks/web3Context';
import { colors } from '../styles';
import { formatNum } from '../utils';

import CustomDialog from './CustomDialog';
import { t } from 'i18next';
import { PATH_ACCOUNT } from '../routes';
import { KLAY, KUSDT } from '../constants';

function randomInRange(min, max) {
  return Math.random() * (max - min) + min;
}

const canvasStyles = {
  position: "fixed",
  pointerEvents: "none",
  width: "100%",
  height: "100%",
  top: 0,
  left: 0
};

function getAnimationSettings(originXA, originXB) {
  return {
    startVelocity: 30,
    spread: 360,
    ticks: 60,
    zIndex: 0,
    particleCount: 150,
    origin: {
      x: randomInRange(originXA, originXB),
      y: Math.random() - 0.2
    }
  };
}

export default function ClaimWinningsDialogComponent({
  setOpen,
  open,
  currentToken,
  winningPrizes = [], // after claim
  hasWinnings, // before claim
  allUnclaimed, // before claim
}) {
  const handleClose = () => setOpen(false);
  const openText = open || ''
  const hasError = typeof openText === 'string' ? openText.includes('error') : false
  const refAnimationInstance = useRef(null);
  const [intervalId, setIntervalId] = useState();

  const nextTickAnimation = useCallback(() => {
    if (refAnimationInstance.current) {
      refAnimationInstance.current(getAnimationSettings(0.1, 0.3));
      refAnimationInstance.current(getAnimationSettings(0.7, 0.9));
    }
  }, []);

  const startAnimation = useCallback(() => {
    if (!intervalId) {
      setIntervalId(setInterval(nextTickAnimation, 400));
    }
  }, [intervalId, nextTickAnimation]);

  const getInstance = useCallback((instance) => {
    refAnimationInstance.current = instance;
  }, []);

  const stopAnimation = useCallback(() => {
    clearInterval(intervalId);
    setIntervalId(null);
    refAnimationInstance.current && refAnimationInstance.current.reset();
  }, [intervalId]);

  useEffect(() => {
    if (open) {
      startAnimation()
    }
  }, [open])

  useEffect(() => {
    return () => {
      clearInterval(intervalId);
    };
  }, [intervalId]);

  const claimedAmount = winningPrizes[0]
  const [ klayPrize, kusdtPrize ] = allUnclaimed || [0, 0]
  let tokenQuery = ''
  if (klayPrize) {
    tokenQuery = `?currentToken=${KLAY}`
  } else if (kusdtPrize) {
    tokenQuery = `?currentToken=${KUSDT}`
  }

  return (
    <Backdrop
      sx={{ backgroundColor: 'rgba(0,0,0, 0.75)', zIndex: 11 }}
      open={open}
      onClick={() => {
        handleClose()
        stopAnimation()
      }}
    >
      <ReactCanvasConfetti refConfetti={getInstance} style={{
        position: "fixed",
        pointerEvents: "none",
        width: "100%",
        height: "100%",
        top: 0,
        left: 0
      }} />
      <Box sx={{display: 'flex', flexDirection: 'column'}}>
        <Typography variant="h1" align="center" sx={{letterSpacing: '-0.5px'}}>
          Congratulations!
        </Typography>
        {hasWinnings ? (
          <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
          <Typography variant="h4" align="center" sx={{mt: 2}}>
          {`You have won a prize!`}
        </Typography>
        <Link sx={{mt: 2}} align="center" variant="h6" href={`/#${PATH_ACCOUNT}${tokenQuery}`}>
          {`Go claim my prize`}
          </Link>
          </Box>
        ) : (
          <>
          <Typography variant="h4" align="center" sx={{mt: 2}}>
            {`You have claimed`}
          </Typography>
          <Typography variant="h4" align="center">
            {`${formatNum(claimedAmount, currentToken, true)}`}
          </Typography>
          </>
        )}
      </Box>
    </Backdrop>
  )
}
