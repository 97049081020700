import { AbiItem } from 'caver-js';

import KlayTogetherDataJson from './KlayTogetherData.json';
import KctYieldSourcePrizePoolJson from './KctYieldSourcePrizePool.json';
import KlayYieldSourcePrizePoolJson from './KlayYieldSourcePrizePool.json';
import IKIP7Json from './IKIP7.json';

export const KlayTogetherData = KlayTogetherDataJson.abi;
export const KctYieldSourcePrizePool = KctYieldSourcePrizePoolJson.abi;
export const KlayYieldSourcePrizePool = KlayYieldSourcePrizePoolJson.abi;
export const IKIP7 = IKIP7Json.abi;
