import React, { useState, useEffect, useRef } from 'react';
import {
  HashRouter as Router,
  Routes,
  Route,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import {
  Backdrop,
  Box,
  Typography,
  Button,
  Stack,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  DialogActions,
  Card,
  Link,
} from '@mui/material'
import { SnackbarProvider } from 'notistack';
import { Provider, useDispatch, useSelector } from 'react-redux';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import confetti from 'canvas-confetti'
import { Close, CheckCircleOutline, MoodBad, ChevronRight } from '@mui/icons-material';
import { isEmpty } from 'lodash'

import { Web3ContextProvider } from './hooks/web3Context';
import { AccountContext } from './contexts/account';
import { PATH_DEPOSIT, PATH_PRIZE, PATH_ACCOUNT, PATH_LANDING } from './routes';
import {
  ADDRESSES,
  KLAYTOGETHER_DATA,
  COMMON,
  CONTRACT,
  KLAY,
  KUSDT,
  SUPPORTED_TOKENS_ARRAY,
  INDEX_KEYS,
} from './constants';
import { theme } from './styles';
import Prize from './containers/Prize';
import Deposit from './containers/Deposit';
import Account from './containers/Account';
import ErrorPage from './containers/ErrorPage';
import AppBar from './components/AppBar';
import BottomNavigation from './components/BottomNavigation';
import ClaimWinningsDialogComponent from './components/ClaimWinningsDialogComponent';
import { BG_COLOR, colors } from './styles';
import i18next from './i18n';
import { store } from './store';
import ErrorWrapper from './ErrorWrapper';
import './App.css';

import {
  getRoundHistoriesThunk,
  getCurrentRoundInfoThunk,
  getUserInformationThunk,
  getWalletBalanceThunk,
} from './services';
import { useWeb3Context } from './hooks/web3Context';
import { KlayTogetherData, IKIP7 } from './abi';

const getTransitionObj = (prevPath, nextPath) => {
  const obj = {
    [PATH_LANDING]: {
      [PATH_PRIZE]: 'next',
      [PATH_ACCOUNT]: 'next',
    },
    [PATH_PRIZE]: {
      [PATH_LANDING]: 'prev',
      [PATH_ACCOUNT]: 'next',
    },
    [PATH_ACCOUNT]: {
      [PATH_LANDING]: 'prev',
      [PATH_PRIZE]: 'prev',
    },
  };
  try {
    const ans = obj[prevPath][nextPath];
    return ans;
  } catch (e) {
    console.error(e);
  }

  return '';
};

const callSize = 50;

if (process.env.NODE_ENV === 'production') {
  console.log = () => { }
  console.error = () => { }
  console.debug = () => { }
}

const AnimatedSwitch = ({ setLang, currentLang, setPath }) => {
  const location = useLocation();
  const { pathname } = location;
  const [prevPath, setprevPath] = useState(PATH_LANDING);
  const [transitionName, setTransitionName] = useState('next');
  const dispatch = useDispatch();
  const { address, provider } = useWeb3Context();

  // fanfare logic
  const navigate = useNavigate()
  const { data } = useSelector((state) => state.user)
  const [showFanfare, setshowFanfare] = useState(false)
  const [showClaim, setshowClaim] = useState(false);
  const [allUnclaimed, setallUnclaimed] = useState([0, 0])

  useEffect(() => {
    if (pathname !== prevPath) {
      const ans = getTransitionObj(prevPath, pathname);
      setTransitionName(ans);
      setPath(pathname);
      setprevPath(pathname);
    }
  }, [location]);

  useEffect(() => {
    dispatch(getRoundHistoriesThunk({ count: callSize }));
    dispatch(getCurrentRoundInfoThunk({}));
  }, []);

  useEffect(() => {
    dispatch(getUserInformationThunk({ address }));
    dispatch(getWalletBalanceThunk({ address }));
  }, [address]);

  useEffect(() => {
    if (data && !isEmpty(data)) {
      const arr = SUPPORTED_TOKENS_ARRAY.map((k) => {
        return Number(data[INDEX_KEYS[k]]?.unclaimedWinnings)
      })
      const hasWinnings = arr.reduce((prev, curr) => {
        return prev + curr
      }, 0)
      if (hasWinnings && !showClaim) {
        setshowFanfare(true)
        setallUnclaimed(arr)
      }
    }
  }, [data])

  return (
    <div
      style={{
        background: `linear-gradient(${BG_COLOR[pathname].start},${BG_COLOR[pathname].finish})`,
        minHeight: '100vh',
      }}
    >
      <ClaimWinningsDialogComponent
        open={showFanfare}
        setOpen={setshowFanfare}
        hasWinnings={showFanfare}
        allUnclaimed={allUnclaimed}
      />
      <AppBar setLang={setLang} currentLang={currentLang} />
      {/* <TransitionGroup>
          <CSSTransition
            key={pathname}
            classNames={transitionName}
            timeout={1000}
          > */}
      <Routes location={location}>
        <Route path={PATH_LANDING} element={<Deposit />} />
        <Route path={PATH_PRIZE} element={<Prize />} />
        <Route path={PATH_ACCOUNT} element={<Account showClaim={showClaim} setshowClaim={setshowClaim} />} />
        <Route path='*' exact element={<ErrorPage />} />
      </Routes>
      {/* </CSSTransition>
      </TransitionGroup> */}
      <BottomNavigation />
    </div>
  );
};

function App() {
  const [currentLang, setLang] = useState('EN');
  const [currentPath, setPath] = useState(PATH_LANDING);
  const notistackRef = useRef();

  useEffect(() => {
    const lower = currentLang.toLowerCase();
    i18next.changeLanguage(lower, (err, t) => {
      if (err) return console.log('something went wrong loading', err);
      // save to local storage: i18nextLng
      localStorage.setItem('i18nextLng', lower);
    });
  }, [currentLang]);

  const onClickDismiss = key => {
    notistackRef.current.closeSnackbar(key);
  }

  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <Router>
          <SnackbarProvider
            ref={notistackRef}
            maxSnack={10}
            autoHideDuration={4000}
            content={(key, message) => {
              let trueKey = String(key)
              const isError = trueKey.includes('error')
              const mainColor = isError ? '#ff4b4b' : '#4338fe'
              let tok = [KLAY]
              try {
                tok = trueKey.includes('deposit-success') ? trueKey.match(/[A-Z]+$/) : tok
              } catch (e) {
                console.error(e)
              }
              const additionalInfo = trueKey.includes('deposit-success') ? (
                <Link href={`/#${PATH_ACCOUNT}?currentToken=${tok[0]}`} sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'start',
                }}>
                  <Typography variant='h2' fontSize="1rem" sx={{ color: mainColor }}>
                    GO TO ACCOUNT
                  </Typography>
                  <IconButton
                    sx={{
                      width: 20,
                      height: 20,
                      color: mainColor,
                    }}
                  >
                    <ChevronRight />
                  </IconButton>
                </Link>
              ) : null

              return (
                <Card sx={{
                  backgroundColor: '#fff',
                  border: `solid 3px ${mainColor}`,
                  px: 2,
                  py: 1,
                  borderRadius: '60px',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}>
                  {isError ? (<MoodBad height="32px" fontSize='small' sx={{ color: mainColor, mr: 1, }} />) : <CheckCircleOutline height="32px" fontSize='small' sx={{ color: mainColor, mr: 1, }} />}
                  <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'start' }}>
                    <Typography variant="h2" fontSize="1.5rem" color={colors.account.text1}>
                      {message}
                    </Typography>
                    {additionalInfo}
                  </Box>
                  <IconButton
                    aria-label="close"
                    onClick={() => onClickDismiss(key)}
                    sx={{
                      width: 30,
                      height: 30,
                      ml: 3,
                      color: mainColor,
                    }}
                  >
                    <Close />
                  </IconButton>
                </Card>
              )
            }}
          >
            <Web3ContextProvider>
              <Provider store={store}>
                <ErrorWrapper>
                  <AnimatedSwitch
                    setLang={setLang}
                    currentLang={currentLang}
                    setPath={setPath}
                    currentPath={currentPath}
                  />
                </ErrorWrapper>
              </Provider>
            </Web3ContextProvider>
          </SnackbarProvider>
        </Router>
      </ThemeProvider>
    </div>
  );
}

export default App;
