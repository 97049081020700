import { createTheme } from '@mui/material/styles';
// import { green, purple, blue, indigo, grey } from '@mui/material/colors';
import { PATH_LANDING, PATH_ACCOUNT, PATH_PRIZE } from '../routes';

// https://material.io/inline-tools/color/
export const COLOR_OBJ = {
  primary: {
    50: '#e8e9fe',
    100: '#c6c8fc',
    200: '#9ea5fa',
    300: '#7181f8',
    400: '#4b63f6',
    500: '#1344f1',
    600: '#0c3be6',
    700: '#002fd9',
    800: '#0021ce',
    900: '#0000be',
  },
  appBar: {
    primary: '#027aff',
  },
};

export const colors = {
  deposit: {
    text1: '#00c1ff',
    text2: '#66beff',
    text3: '#4338fe',
    backgroundColor1: 'rgba(216, 216, 216, 0.5)',
    backgroundColor2: '#007aff',
  },
  account: {
    backgroundColor1: '#f8f8f8',
    backgroundColor2: `linear-gradient(#a08eff, #5385ff)`,
    text1: '#141414',
    text2: '#3d3d3d',
    text3: '#4338fe',
    text4: '#8f8f8f',
    buttonColor1: '#40bfff',
    divider: '#ebebeb',
  },
};

export const BG_COLOR = {
  [PATH_LANDING]: {
    start: '#bbe9fe',
    finish: '#56b7ff',
  },
  [PATH_PRIZE]: {
    start: '#b1f3ff',
    finish: '#30a9d4',
  },
  [PATH_ACCOUNT]: {
    start: '#5a55f9',
    finish: '#7228e6',
  },
};

export const GRADIENT_PERCENT = '70%'; // 90%
export const rgbaLight = 'rgba(255,255,255,0.2)';

export const theme = createTheme({
  palette: {
    mode: 'light',
    divider: 'rgba(0,0,0,0.8)',
    text: {
      primary: '#fff',
      secondary: '#000',
      disabled: 'rgba(255, 255, 255, 0.5)',
    },
    primary: {
      main: '#fff',
    },
    secondary: {
      main: '#482cff',
    },
    error: {
      main: '#d32f2f',
    },
    warning: {
      main: '#ed6c02',
    },
    info: {
      main: '#0288d1',
    },
    success: {
      main: '#2e7d32',
    },
    action: {
      active: '#fff',
      hover: 'rgba(255, 255, 255, 0.08)',
      selected: 'rgba(255, 255, 255, 0.16)',
      disabled: 'rgba(255, 255, 255, 0.5)',
      // disabledBackground: 'rgba(255, 255, 255, 0.12)'
    },
    background: {
      default: rgbaLight,
      paper: '#fff',
      card: rgbaLight,
    },
  },
  spacing: 16, // [0, 4, 8, 16, 32, 64],
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  typography: {
    fontFamily: 'Poppins,sans-serif',
    fontWeight: 400, // 100~800
    textTransform: 'capitalize',
    letterSpacing: '-0.5px',
    h1: {
      fontWeight: 900, // black 900
      fontSize: '4rem',
      textTransform: 'uppercase',
      letterSpacing: '-2px',
    },
    h2: {
      fontWeight: 800, // extrabold
      textTransform: 'uppercase',
    },
    h3: {
      fontWeight: 700, // bold
    },
    h4: {
      fontWeight: 700,
    },
    h5: {
      fontWeight: 600, // semibold
      textTransform: 'uppercase',
    },
    h6: {
      fontWeight: 500, // medium
      fontSize: '1rem',
    },
    h7: {
      fontWeight: 400, // regular
      fontSize: '0.85rem',
    },
    // 300 // light
    // 200 // extralight
    // 100 // thin
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '24px',
          borderWidth: 2,
          fontWeight: 700,
          height: 64,
          '&:hover': {
            backgroundColor: 'transparent',
          }
        },
        textSizeLarge: {
          fontSize: '1.1rem',
        },
      },
      defaultProps: {
        size: 'large',
        variant: 'outlined',
        disableElevation: true,
        fullWidth: false,
        disableRipple: true,
      },
      variants: [
        {
          props: {
            variant: 'contained',
          },
          style: {
            backgroundColor: '#4040f4',
            color: '#fff',
            boxShadow: 'none',
            '&:hover': {
              backgroundColor: 'rgba(64,	64,	244, 0.5)',
            },
            fontSize: '1.25rem',
            fontWeight: 700,
            paddingLeft: 53,
            paddingRight: 53,
            paddingTop: 18,
            paddingBottom: 18,
            borderRadius: '24px',
          },
          defaultProps: {
            size: 'large',
          }
        },
        {
          props: {
            variant: 'outlined',
          },
          style: {
            borderWidth: 1.5,
            borderStyle: 'solid',
            backgroundColor: 'transparent',
            borderColor: '#482cff',
            boxShadow: 'none',
            color: '#482cff',
            '&:hover': {
              borderColor: 'rgba(72,	44,	255, 0.5)',
              color: 'rgba(72,	44,	255, 0.5)',
              borderWidth: 1.5,
            }
          },
        },
        {
          props: {
            variant: 'dialog-outlined',
          },
          style: {
            borderWidth: 1.5,
            height: 36,
            fontWeight: 700, // bold
            fontSize: '0.75rem',
            borderStyle: 'solid',
            backgroundColor: 'transparent',
            borderColor: '#482cff',
            boxShadow: 'none',
            color: '#482cff',
            padding: '10px 40px',
            '&:hover': {
              borderColor: 'rgba(72,	44,	255, 0.5)',
              color: 'rgba(72,	44,	255, 0.5)',
              borderWidth: 1.5,
            }
          },
        },
      ],
    },
    MuiCard: {
      styleOverrides: {
        root: {
          backgroundColor: rgbaLight,
          boxShadow: 'none',
        },
      },
      defaultProps: {
        raised: false,
      },
    },
    MuiFilledInput: {
      defaultProps: {
        margin: 'dense',
      },
    },
    MuiFormControl: {
      defaultProps: {
        margin: 'normal',
      },
    },
    MuiFormHelperText: {
      defaultProps: {
        margin: 'normal',
      },
    },
    MuiIconButton: {
      defaultProps: {
        size: 'small',
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          '&:hover': {
            backgroundColor: 'transparent'
          },
        }
      }
    },
    MuiInputLabel: {
      defaultProps: {
        margin: 'dense',
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          // border: 'none',
        },
        divider: {
          // border: 'none',
          borderWidth: 12,
          borderColor: 'transparent',
        },
      },
      defaultProps: {
        dense: false,
      },
    },
    MuiOutlinedInput: {
      defaultProps: {
        margin: 'dense',
      },
      styleOverrides: {
        root: {
          borderColor: 'transparent',
          color: '#141414',
          backgroundColor: '#fff',
          borderRadius: '16px',
        },
      },
    },
    MuiInputBase: {
      defaultProps: {
        margin: 'dense',
      },
      styleOverrides: {
        input: {
          color: '#482cff',
          textAlign: 'end',
          fontSize: '1.5rem',
          padding: 0,
        },
      },
    },
    MuiFab: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiTable: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiTextField: {
      defaultProps: {
        margin: 'normal',
      },
    },
    MuiToolbar: {
      defaultProps: {
        variant: 'dense',
      },
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          // backgroundColor: PRIMARY,
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          // backgroundColor: 'transparent',
          // boxShadow: 'none',
          // border: 'non',
          borderRadius: '16px',
        },
      },
      defaultProps: {
        raised: false,
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
        },
      },
    },
    MuiBottomNavigation: {
      styleOverrides: {
        root: {
          backgroundColor: rgbaLight,
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          color: '#fff',
        },
      },
    },
    MuiBox: {
      styleOverrides: {
        root: {
          display: 'flex',
          justifyContent: 'center',
        },
      },
    },
    MuiButtonGroup: {
      styleOverrides: {
        groupedTextHorizontal: {
          border: 'none !important',
          paddingRight: '20px',
          paddingLeft: '20px',
        },
      },
      defaultProps: {
        disableRipple: true,
        disableFocusRipple: true,
      }
    },
    MuiDialog: {
      styleOverrides: {
        root: {
          color: '#000',
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          color: '#000',
          textAlign: 'left',
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          color: '#3d3d3d',
          fontSize: '1rem',
          fontWeight: 500,
        },
      },
    },
    MuiPopover: {
      paper: {
        color: '#000',
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          color: '#000',
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          width: 'auto',
          height: '100%',
        },
      },
    },
    MuiSkeleton: {
      styleOverrides: {
        root: {
          backgroundColor: '#d8d8d8',
        }
      }
    }
  },
});
