import React from 'react'
import {
  Box,
  Typography,
  ButtonGroup,
  IconButton,
} from '@mui/material'
import { GitHub, Telegram, MenuBook } from '@mui/icons-material';

import {
  DOCS_URL,
  TELEGRAM_URL,
  GITHUB_URL,
} from '../constants'
import { PcRoot } from './BottomNavigation';
import { MobileRoot } from './AppBar'

const BottomButtonGroup = () => {
  return (
    <Box sx={{ zIndex: 10 }}>
      <Typography align="center" sx={{ color: '#fff' }}>
        KlayTogether 2022.
      </Typography>
      <Box sx={{
        display: 'flex',
        flexDirection: 'row',
        gap: 0.5,
        justifyContent: 'center',
      }}>
        <IconButton color="primary" onClick={() => {
          window.open(TELEGRAM_URL, '_blank')
        }}>
          <Telegram sx={{ height: 25 }} />
        </IconButton>
        <IconButton color="primary" onClick={() => {
          window.open(DOCS_URL, '_blank')
        }}>
          <MenuBook sx={{ height: 25 }} />
        </IconButton>
        <IconButton color="primary" onClick={() => {
          window.open(GITHUB_URL, '_blank')
        }}>
          <GitHub sx={{ height: 25 }} />
        </IconButton>
      </Box>
    </Box>
  )
}

const BottomFooter = ({ sx }) => {
  return (
    <>
      <MobileRoot>
        <Box sx={{ mb: 1, ...sx }}>
          <BottomButtonGroup />
        </Box>
      </MobileRoot>
      <PcRoot>
        <Box sx={{ my: 3 }}>
          <BottomButtonGroup />
        </Box>
      </PcRoot>
    </>
  )
}

export default BottomFooter
