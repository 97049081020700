import React, { useState } from 'react';
import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

import DepositDialogComponent from './DepositDialogComponent';

export default function DepositButtonComponent({ token, fullWidth }) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  return (
    <>
      <Button
        variant="contained"
        onClick={() => setOpen(true)}
        fullWidth={fullWidth}
      >
        {t('Deposit')}
      </Button>
      <DepositDialogComponent open={open} token={token} onClose={setOpen} />
    </>
  );
}
