import { configureStore } from '@reduxjs/toolkit';
// Or from '@reduxjs/toolkit/query/react'
import { setupListeners } from '@reduxjs/toolkit/query';
import {
  PRIZES,
  prizesSlice,
  ROUNDS,
  roundsSlice,
  USER,
  userSlice,
  WALLET_BALANCE,
  walletBalanceSlice,
} from './services';

export const store = configureStore({
  reducer: {
    // Add the generated reducer as a specific top-level slice
    [PRIZES]: prizesSlice.reducer,
    [ROUNDS]: roundsSlice.reducer,
    [USER]: userSlice.reducer,
    [WALLET_BALANCE]: walletBalanceSlice.reducer,
  },
  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  // middleware: (getDefaultMiddleware) =>
  //   getDefaultMiddleware().concat(coinGeckoApi.middleware),
});

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch);
