import React from 'react';
import {
  Box,
  Typography,
  Button,
  Container,
} from '@mui/material'
import { useNavigate } from 'react-router-dom';

import { PATH_LANDING } from './routes';
import ErrorPage from './containers/ErrorPage';

export const withRouter = (Component) => {
  const Wrapper = (props) => {
    const navigate= useNavigate()

    return (
      <Component
        navigate={navigate}
        {...props}
        />
    );
  };

  return Wrapper;
};

class ErrorWrapper extends React.Component {
  constructor(props) {
   super(props);
   this.state = {
    errorFound: false,
   };
  }

 componentDidCatch(error, info) {
   this.setState({
    errorFound: true
   });
  }

 render() {
   const { errorFound } = this.state
   const { navigate } = this.props
    if (errorFound) {
      return <ErrorPage onReset={() => {
        this.setState({
          errorFound: false,
        }, () => {
          window.location.href = '/'
        })
      }} />
    }
    return this.props.children;
  }
 }

 export default withRouter(ErrorWrapper)
