import React, { useEffect, useState } from 'react';
import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useWeb3Context } from '../hooks/web3Context';
import ConnectWalletDialog from './ConnectWalletDialog';

export default function ConnectButton({ token, amount }) {
  const { t } = useTranslation();

  const { connect } = useWeb3Context();
  const [clickedConnect, setclickedConnect] = useState(false);

  return (
    <>
      <Button variant="contained" onClick={() => setclickedConnect(true)}>
        {t('Connect wallet')}
      </Button>
      <ConnectWalletDialog
        open={clickedConnect}
        setOpen={setclickedConnect}
        connect={connect}
      />
    </>
  );
}
