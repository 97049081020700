import Caver from 'caver-js';
import BigNumber from 'bignumber.js';
import confetti from 'canvas-confetti';

import { DECIMAL, URL, KLAY } from '../constants';

const caver = new Caver(process.env.REACT_APP_NETWORK_URL);
export const BN = BigNumber.clone(); // .set({ FORMAT: {} });

export const truncAddress = (address, full = false) => {
  if (!address) {
    return '';
  }
  if (!full) {
    return `${address.slice(0, 6)}...`;
  }
  return `${address.slice(0, 6)}...${address.slice(address.length - 4)}`;
};

export const formatNum = (num, token, showUnit, asNumber, roundTo = 6) => {
  const unit = showUnit ? ` ${token}` : '';
  if (num === 0 || num === '0' || !num) {
    return `0${unit}`;
  }

  const number = new BN(num);

  if (number.isNaN()) {
    return num;
  }

  if (!token) {
    if (number.isInteger()) {
      return number.toFormat();
    }
    return number.toFormat(roundTo);
  }

  if (token === KLAY) {
    const converted = caver.utils.convertFromPeb(number);
    const convertedBN = new BN(converted);
    if (asNumber) {
      return convertedBN.toNumber();
    }
    return `${convertedBN.toFormat(roundTo)}${unit}`;
  }
  const converted = number.dividedBy(10 ** DECIMAL[token]);
  if (asNumber) {
    return converted.toNumber();
  }
  return `${converted.toFormat(roundTo)}${unit}`;
};

export const getCurrentBlock = () => {
  return caver.rpc.klay.getBlockNumber();
};

export const toPeb = (num, token) => {
  if (!num && num != 0) {
    return 0;
  }

  return caver.utils.convertToPeb(num);
};

export const showFanfareFunc = () => {
  // show confetti
  const duration = 10 * 1000;
  const animationEnd = Date.now() + duration;
  const defaults = {
    startVelocity: 30,
    spread: 360,
    ticks: 60,
    zIndex: 0,
    resize: true,
    disableForReducedMotion: true,
  };
  const randomInRange = (min, max) => {
    return Math.random() * (max - min) + min;
  };

  const interval = setInterval(function () {
    const timeLeft = animationEnd - Date.now();

    if (timeLeft <= 0) {
      return clearInterval(interval);
    }

    const particleCount = 60 * (timeLeft / duration);
    // since particles fall down, start a bit higher than random
    confetti(
      Object.assign({}, defaults, {
        particleCount,
        origin: { x: randomInRange(0.1, 0.3), y: Math.random() - 0.2 },
      }),
    );
    confetti(
      Object.assign({}, defaults, {
        particleCount,
        origin: { x: randomInRange(0.7, 0.9), y: Math.random() - 0.2 },
      }),
    );
  }, 250);
};

export const getUTC = () => Math.floor(new Date().getTime() / 1000);
