import React, { useEffect, useState } from 'react';
import { Button, IconButton } from '@mui/material';
import ButtonUnstyled, {
  buttonUnstyledClasses,
} from '@mui/base/ButtonUnstyled';
import { styled } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { AccountCircle } from '@mui/icons-material';
import { useLocation } from 'react-router-dom';

// hooks
import { useWeb3Context } from '../hooks/web3Context';
// utils
import { DEFAULT_NETWORK_ID } from '../constants';
import { truncAddress } from '../utils';
import { rgbaLight, COLOR_OBJ } from '../styles';
import { PATH_ACCOUNT } from '../routes';
// components
import AccountDialogComponent from './AccountDialogComponent';

const blue = {
  500: '#007FFF',
  600: '#0072E5',
  700: '#0059B2',
};

const CustomButtonRoot = styled('button')`
  font-family: Poppins, sans-serif;
  font-weight: 800;
  font-size: 0.79rem;
  background-color: transparent;
  border: 2px solid white;
  padding: 12px 12px;
  border-radius: 40px;
  color: white;
  transition: all 150ms ease;
  cursor: pointer;

  &:hover {
    background-color: ${rgbaLight};
  }

  &.${buttonUnstyledClasses.active} {
    background-color: ${rgbaLight};
  }

  &.${buttonUnstyledClasses.focusVisible} {
    box-shadow: 0 4px 20px 0 rgba(61, 71, 82, 0.1),
      0 0 0 5px rgba(0, 127, 255, 0.5);
    outline: none;
  }

  &.${buttonUnstyledClasses.disabled} {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

function CustomButton(props) {
  return <ButtonUnstyled {...props} component={CustomButtonRoot} />;
}

export default function AppBarConnectButton() {
  const { t } = useTranslation();
  const {
    disconnect,
    address,
    connected,
    connect,
    checkWrongNetwork,
    chainID,
  } = useWeb3Context();
  const [openAcctDialog, setopenAcctDialog] = useState(false);
  const { pathname } = useLocation();

  let buttonText = t('Connect wallet');
  let clickFunc = window?.klaytn?.isKaikas
    ? () => connect()
    : () => setopenAcctDialog(true);
  const localConnected = sessionStorage.getItem('klaytogether-connected');

  useEffect(() => {
    /*
      - 과거에 연결한 흔적 (session storage)이 있다면
      - connect 호출해보기
    */
    if (localConnected) {
      connect(true);
    }
  }, []);

  if (connected) {
    if (chainID != DEFAULT_NETWORK_ID) {
      buttonText = t('Wrong network');
      clickFunc = () => {
        checkWrongNetwork();
      };
    } else {
      buttonText = truncAddress(address);
      clickFunc = () => setopenAcctDialog(true);
    }
  }

  if (!connected) {
    return null;
  }

  return (
    <>
      <Button
        startIcon={<AccountCircle sx={{ height: 20 }} />}
        onClick={clickFunc}
        variant="text"
        sx={{
          color: pathname === PATH_ACCOUNT
          ? '#fff'
          : COLOR_OBJ.appBar.primary,
          fontWeight: 800,
          fontSize: '1rem',
          textTransform: 'none',
        }}
      >
        {t(buttonText)}
      </Button>
      <AccountDialogComponent
        open={openAcctDialog}
        setOpen={setopenAcctDialog}
        account={address}
        disconnect={disconnect}
      />
    </>
  );
}
