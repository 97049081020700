import React from 'react'
import {
  Container,
  Box,
  Typography,
  Button,
} from '@mui/material'
import { useNavigate } from 'react-router-dom';

import { PATH_LANDING } from '../routes'
import { BG_COLOR } from '../styles';

const ErrorPage = ({
  onReset,
}) => {
  const navigate= useNavigate()
  return (
    <Container sx={{
      display: 'flex',
      flexDirection: 'column',
      gap: 5,
      justifyContent: 'center',
      alignItems: 'center',
      background: `linear-gradient(${BG_COLOR[PATH_LANDING].start},${BG_COLOR[PATH_LANDING].finish})`,
      minHeight: '100vh',
      minWidth: '100vw',
    }}>
      <Box>
        <Typography variant="h3" textAlign="center">Hmm...</Typography>
        <Typography variant="h3" textAlign="center">Something doesn't look right...</Typography>
      </Box>
      <Button
        size="large"
        sx={{
          borderColor: '#fff',
          color: '#fff'
        }}
        onClick={() => {
          navigate(PATH_LANDING)
          if (onReset) {
            onReset()
          }
        }}>
        Go back home
      </Button>
    </Container>
  )
}

export default ErrorPage
