import React, { useState, useRef, useEffect } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  ListItem,
  ListItemText,
  ListSubheader,
  Box,
  IconButton,
  Typography,
  Link,
  Snackbar,
  Card,
  Stack,
  Chip,
  Divider,
  Grid,
} from '@mui/material';
import { Close, CallMade } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

import CustomDialog from './CustomDialog';

export default function ConnectWalletDailog({ setOpen, open, connect }) {
  const { t } = useTranslation();
  const handleClose = () => setOpen(false);

  const isMobile = navigator?.userAgent.includes('Mobile')
  if (isMobile) {
    return (
      <CustomDialog
        title={t('Connect wallet')}
        handleClose={handleClose}
        open={open}
      >
        <DialogContent>
          Please use your desktop Kaikas chrome extension to connect your wallet.
        </DialogContent>
        <DialogActions>
          <Button
            color="secondary"
            onClick={() => {
              handleClose();
            }}
          >
            {t('OK')}
          </Button>
        </DialogActions>
      </CustomDialog>
    );
  }

  return (
    <CustomDialog
      title={t('Connect wallet')}
      handleClose={handleClose}
      open={open}
    >
      <DialogContent>
        <span>{t('connect wallet disclaimer')}</span>
        <br />
        <br />
        <span>{t('open or install to continue')}</span>
      </DialogContent>
      <DialogActions>
        <Button
          size="small"
          variant="dialog-outlined"
          color="secondary"
          onClick={() => {
            handleClose();
            connect();
          }}
        >
          {t('Open or install Kaikas')}
        </Button>
      </DialogActions>
    </CustomDialog>
  );
}
