import React, {
  useState,
  ReactElement,
  useContext,
  useMemo,
  useCallback,
} from 'react';
import { JsonRpcProvider } from '@ethersproject/providers';
import Caver from 'caver-js';
import { useSnackbar } from 'notistack';

import {
  DEFAULT_NETWORK_ID,
  DEFAULT_NETWORK_URL,
  ADDRESSES,
  CONTRACT,
  KLAY,
  KUSDT,
  KLAYTOGETHER_DATA,
  COMMON,
} from '../constants';
import {
  IKIP7,
  KctYieldSourcePrizePool,
  KlayYieldSourcePrizePool,
  KlayTogetherData,
} from '../abi';

const Web3Context = React.createContext(null);

export const useWeb3Context = () => {
  const web3Context = useContext(Web3Context);
  if (!web3Context) {
    throw new Error(
      'useWeb3Context() can only be used inside of <Web3ContextProvider />, ' +
        'please declare it at a higher level.',
    );
  }
  const { onChainProvider } = web3Context;
  return useMemo(() => {
    return { ...onChainProvider };
  }, [onChainProvider]);
};

export const useAddress = () => {
  const { address } = useWeb3Context();
  return address;
};

export const Web3ContextProvider = ({ children }) => {
  const [connected, setConnected] = useState(false);
  const [chainID, setChainID] = useState(DEFAULT_NETWORK_ID);
  const [address, setAddress] = useState('');
  const { enqueueSnackbar } = useSnackbar();

  const [provider, setProvider] = useState(new Caver(DEFAULT_NETWORK_URL));

  const _initListeners = useCallback((rawProvider) => {
    if (!rawProvider.on) {
      return;
    }

    rawProvider.on('accountsChanged', async (accounts) => {
      console.log({ accounts });
      setTimeout(() => window.location.reload(), 1);
    });

    rawProvider.on('networkChanged', async (chain) => {
      console.log(chain);
      changeNetwork(chain);
    });

    rawProvider.on('network', (_newNetwork, oldNetwork) => {
      if (!oldNetwork) return;
      window.location.reload();
    });
  }, []);

  const changeNetwork = async (otherChainID) => {
    const network = Number(otherChainID);
    setChainID(network);
    enqueueSnackbar('Network successfully changed!', {
      key: 'network-change'
    });
  };

  const connect = useCallback(
    async (isOnMount = false) => {
      if (!window.klaytn || !window.klaytn.isKaikas) {
        window.open('https://docs.kaikas.io/01_getting_started/02_quick_start');
        return null;
      }

      const isApproved = await window?.klaytn?._kaikas.isApproved();
      const isUnlocked = await window?.klaytn?._kaikas.isUnlocked();

      if (isOnMount && !isUnlocked) {
        // quietly exit
        return;
      }

      const address = await window.klaytn.enable();

      _initListeners(window.klaytn);

      const connectedProvider = new Caver(window.klaytn);

      const chainId = await connectedProvider.rpc.net
        .getNetworkId()
        .then((network) => Number(network));

      setAddress(address[0]);

      setChainID(chainId);

      if (chainId == DEFAULT_NETWORK_ID) {
        setProvider(connectedProvider);
      }

      setConnected(true);
      sessionStorage.setItem('klaytogether-connected', true);
      enqueueSnackbar('Connected!', {
        preventDuplicate: true,
        key: 'connected'
      });

      return connectedProvider;
    },
    [_initListeners],
  );

  const disconnect = useCallback(async () => {
    setConnected(false);
    sessionStorage.removeItem('klaytogether-connected');
    enqueueSnackbar('Disconnected!', {
      key: 'disconnected'
    });
    setTimeout(() => {
      window.location.reload();
    }, 100);
  }, []);

  const onChainProvider = useMemo(() => {
    const checkWrongNetwork = async () => {
      if (chainID != DEFAULT_NETWORK_ID) {
        window.alert('switch network');
        return true;
      }
      return false;
    };

    return {
      connect,
      disconnect,
      provider,
      connected,
      address,
      chainID,
      checkWrongNetwork,
    };
  }, [connect, disconnect, provider, connected, address, chainID]);
  //@ts-ignore
  return (
    <Web3Context.Provider value={{ onChainProvider }}>
      {children}
    </Web3Context.Provider>
  );
};
