import React, { useState } from 'react';
import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import {
  CircularProgress,
} from '@mui/material'

import { depositThunk, approveThunk } from '../services';
import { useWeb3Context } from '../hooks/web3Context';
import {
  IKIP7,
  KlayYieldSourcePrizePool,
  KctYieldSourcePrizePool,
  KlayTogetherData,
} from '../abi';
import {
  ADDRESSES,
  CONTRACT,
  KUSDT,
  KLAY,
  INDEX_KEYS,
  COMMON,
  PRIZE_POOL,
} from '../constants';

export default function DepositButtonAction({ token, amount, cb }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { address } = useWeb3Context();
  const { data: userData, isLoading } = useSelector((state) => state.user);
  const { data: balances } = useSelector((state) => state.walletBalance);
  const { enqueueSnackbar } = useSnackbar();

  const { allowance } = userData?.length ? userData[INDEX_KEYS[token]] : {};
  const balance = balances[token];

  let hasAllowance = true;
  if (token !== KLAY) {
    console.log({ allowance, balance });
    if (Number(allowance) < Number(balance)) {
      hasAllowance = false;
    }
  }

  const finalAction = () => {
    if (hasAllowance) {
      dispatch(depositThunk({ address, amount, cb, token }));
    } else {
      dispatch(
        approveThunk({
          address,
          token,
          poolAddress: ADDRESSES[PRIZE_POOL][token], //pool이 토큰을 사용하는것에 대해 approve를 받아야 함
          cb: (message, {key}) => {
            enqueueSnackbar(message, {
              key,
            });
          },
        }),
      );
    }
  };
  const btnTitle = hasAllowance ? t('Deposit') : t('Approve');

  return (
    <Button
      variant="contained"
      disabled={
        Number(balance) < Number(amount) || Number(amount) < 1 || !amount || isLoading
      }
      onClick={finalAction}
    >
      {isLoading ? <CircularProgress size="1.5rem" /> : btnTitle}
    </Button>
  );
}
