import React, { useState } from 'react';
import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

import WithdrawDialogComponent from './WithdrawDialogComponent';
import { colors } from '../styles';

export default function WithdrawButtonComponent({ token, fullWidth }) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  return (
    <>
      <Button
        sx={{ backgroundColor: colors.account.buttonColor1 }}
        fullWidth={fullWidth}
        variant="contained"
        onClick={() => setOpen(true)}
      >
        {t('Withdraw')}
      </Button>
      <WithdrawDialogComponent
        open={open}
        token={token}
        onClose={setOpen}
        isWithdraw
      />
    </>
  );
}
