export const PATH_LANDING = '/';
export const PATH_DEPOSIT = '/deposit';
export const PATH_PRIZE = '/prize';
export const PATH_ACCOUNT = '/account';

export const ROUTE_NAME_OBJ = {
  [PATH_LANDING]: 'Deposit',
  [PATH_PRIZE]: 'Prize',
  [PATH_ACCOUNT]: 'Account',
}
